import React from "react"
import admin from "../assets/casestudies/unity-admin.png"
import unity from "../assets/casestudies/unity-data.png"
import checkout from "../assets/casestudies/dnb-main.png"
import simpsonsThum from "../assets/simpsons.jpg"
import CenteredContent from "../components/centeredContent"
import FullWidthContent from "../components/fullWidthContent"
import Layout from "../components/layout"

const IndexPage = ({ data }) => (
  <Layout
    headerType="home"
    headerText={
      "Hi, I'm Gus Higuera a Engineering Manager and Full Stack Developer in Austin, TX"
    }
  >
    <CenteredContent
      image1={admin}
      header="About Me"
      body="For over 20 years, I've worked in software engineering, leveraging my work in design, front-end and back-end technologies to deliver exceptional products and impactful user experiences for companies like Unity, PayPal, Dun & Bradstreet, Warner Bros. and Idealab. As an Engineering Manager, I have a strong track record of guiding teams to success through empathetic leadership, innovation, and driving strategic initiatives that empower both team growth and project excellence."
      rotation="3deg"
      buttonText="See my portfolio"
      buttonLink="/portfolio"
    />
    <FullWidthContent
      image={checkout}
      header="Bridging Design, Engineering & Data"
      body="As a full stack developer and tech lead, I provide strategic technical direction, contribute to front-end and back-end development, and guide teams to deliver complex projects using my expertise."
      rotation="3deg"
      topTextMargin="-25px"
      bottomAngleMargin="-40px"
      buttonText="See my portfolio"
      buttonLink="/portfolio"
    />
    <CenteredContent
      image1={unity}
      header="Check Out My Most Recent Work"
      body="At Unity, I led the Subscription and Metered Billing teams within the Commerce Platform, managing complex platform and data pipeline systems that supported a seamless customer experience, generated millions in revenue, and tracked billions of transactions."
      rotation="-3deg"
      buttonText="See my latest work"
      buttonLink="/portfolio/unity"
    />
    <FullWidthContent
      image={simpsonsThum}
      header="Some Cool Stuff"
      body="Throughout my career, I've had the privilege of collaborating with exceptional teams and earning recognition, including the Webby Award-winning site for The Simpsons Movie and the Ady for the Green Car Hawaii application"
      bottomAngleMargin="-40px"
      bgcolor1={"#ffcc00"}
      topTextMargin="-35px"
      rotation="-3deg"
      buttonText="See my LinkedIN profile"
      buttonLink="https://www.linkedin.com/in/gushiguera"
    />
  </Layout>
)

export default IndexPage
